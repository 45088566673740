import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CommunityLogo = ({
  communityLogo = null,
  communityName = null,
  altText = "",
  slug = "/",
}) => {
  const data = useStaticQuery(graphql`
    query communityLogoPlaceholder {
      allFile(filter: { relativePath: { eq: "Header-Logo-Crop.png" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 400
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  `)
  const defaultCommunityLogo = data.allFile.edges[0].node
  const imageData = communityLogo ? communityLogo : defaultCommunityLogo
  const image = getImage(imageData)

  return (
    <Link
      className="flex flex-col justify-end text-white   transform hover:scale-105 "
      style={{ maxWidth: 400 }}
      to={`/${slug}`}
    >
      <div className="mx-auto  w-10/12">
        <GatsbyImage image={image} alt={altText} />
      </div>
      {communityName && (
        <h3 className="mt-2 mb-6 font-heading text-base sm:text-xl text-center">
          {communityName}
        </h3>
      )}
    </Link>
  )
}
export default CommunityLogo
